import aws_exports from '../aws-exports';
import Amplify from 'aws-amplify'

let isLocalhost = false;

export const configAmplify = () => {
  
  // by default, say it's localhost
  const oauth = {
    domain: process.env.GATSBY_APP_AUTH_DOMAIN,
    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
    redirectSignIn: process.env.GATSBY_APP_DOMAIN_URL+'/user/callback/',
    redirectSignOut: process.env.GATSBY_APP_DOMAIN_URL+'/user/callback/',
    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
  };

  // copy the constant config (aws-exports.js) because config is read only.
  var configUpdate = aws_exports;
  // update the configUpdate constant with the good URLs
  configUpdate.oauth = oauth;

 // try {
    if (typeof window !== 'undefined') {
      try {
        Amplify.configure(configUpdate);
      } catch (error) {
        console.log(error)
      }
    }
//  } catch (error) {
 //   console.log(error)
 // }
}