import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

// import Amplify from 'aws-amplify'
// import config from '../aws-exports'
// Amplify.configure(config)

import { configAmplify } from '../utils/ciAmplify';

try {
 configAmplify();
} catch (error) {
 console.log(error);
}

const IndexPage = () => (
  <Layout>


<div class="container">
        <section>
          <div class="row d-flex justify-content-between">
            <div class="col-md-5">
              <div class="body-text-pane">
                <div class="form-pane section-pane">
                
                  <h1 class="text-center text-md-left">
                    Know someone that would love to give on a monthly basis?
                  </h1>
                  <p class="lead text-center text-md-left">
                    We'll send you $10 charitable dollars for every referral that creates a monthly deposit with Charitable Impact!
                  </p>

                  <p class="text-center text-md-left">
                    <a
                      href="/cp/signup"
                      class="btn btn-primary btn-cta cta-signup"
                      >Sign up as an Affiliate</a
                    >
                  </p>

                </div>
              </div>
            </div>
            <div class="col-md-6 text-center text-md-right">
             <div class="body-bg-img-pane" style={{backgroundImage: "url(https://f.hubspotusercontent40.net/hubfs/2004091/giving.charitableimpact.com/recurring-deposit-lp/recurringdep_RECURRING%20IMG%20PRINCIPAL_resized.jpg)"}}>

             </div>
            </div>
          </div>
        </section>

       
           </div>


  </Layout>
)

export default IndexPage
