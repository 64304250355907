/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://z08yl3ber9.execute-api.us-east-1.amazonaws.com/dev",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://h4auix5z4rfaxgumxf3lkpv6km.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "us-east-1:b3b77f87-42e3-4249-be4b-ae9e7003acc6",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_y4jKbFaTg",
    "aws_user_pools_web_client_id": "1ksbk6dusjdrkbe9gm9t1d2ca4",
    "oauth": {}
};


export default awsmobile;
